<template>
  <div class="">
    <div class="top-button-area p-m-2">
      <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Yeni" @click="_ModelModal()" />
    </div>
    <DataTable v-if="DataTable_render" :columns="columns" :data="UrunModelListesi" v-on:set-child-data="getDataChild_Model" />

    <Dialog v-model:visible="ModelModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '25rem' }" :modal="true">
      <template #header>
        <h4>Yeni Model</h4>
      </template>
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">
          <label for="spanModel">Model Adı</label>
          <InputText type="text" v-model="v$.Model.$model" aria-labelledby="spanModel"
            :class="{ 'p-invalid': v$.Model.$invalid && submitted }" />
          <small v-if="(v$.Model.$invalid && submitted) || v$.Model.$pending.$response
        " class="p-error">{{ v$.Model.required.$message.replace("Value", "Name") }}</small>
        </div>
        <div class="p-field p-col-6"></div>
        <div class="p-field p-col-6">
          <label for="UrunTur" :class="{
        'p-error': v$.SelectedUrunTur.$invalid && submitted,
      }">Cihaz türü</label>
          <Dropdown filter id="UrunTur" v-model="SelectedUrunTur" :options="UrunTurTablosu" :class="{
        'p-invalid': v$.SelectedUrunTur.$invalid && submitted,
      }" optionLabel="UrunAdi" optionValue="UrunTur_Rowid" placeholder="Seçiniz..." />
          <small v-if="(v$.SelectedUrunTur.$invalid && submitted) ||
        v$.SelectedUrunTur.$pending.$response
        " class="p-error">{{
        v$.SelectedUrunTur.required.$message.replace(
          "Value",
          "Name"
        )
      }}</small>
        </div>

        <div class="p-field p-col-6">
          <label for="Marka" :class="{
          'p-error': v$.SelectedMarka.$invalid && submitted,
        }">Cihaz Markası</label>

          <Dropdown filter id="Marka" v-model="SelectedMarka" :options="MarkaTablosu" :class="{
        'p-invalid': v$.SelectedMarka.$invalid && submitted,
      }" optionLabel="MarkaAd" optionValue="Marka_Rowid" placeholder="Seçiniz..." />
          <small v-if="(v$.SelectedMarka.$invalid && submitted) ||
        v$.SelectedMarka.$pending.$response
        " class="p-error">{{
        v$.SelectedMarka.required.$message.replace(
          "Value",
          "Name"
        )
      }}</small>
        </div>
      </div>

      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)" autofocus />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this._ModelModal()" />
      </template>
    </Dialog>


  </div>
</template>

<script>
import DataTable from "../../DataTable/DataTable.vue";
import GlobalServis from "@/services/GlobalServis";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      ModelModal: false,
      UrunModelListesi: [],
      MarkaTablosu: [],
      UrunTurTablosu: [],
      columns: [
        { field: "ModelAd", header: "Model Ad" },
        { field: "MarkaAd", header: "Marka Ad" },
        { field: "TurAd", header: "Tür Ad" },
      ],
      Model: "",
      Model_rowid: "",
      SelectedUrunTur: 0,
      SelectedMarka: 0
    };
  },
  methods: {
    getDataChild_Model(getdata_child) {
      this.ModelModal = !this.ModelModal;
      this.ModelModelDurum = false;
      this.Model_rowid = getdata_child.data.Model_rowid;
      let data = {
        Model_rowid: getdata_child.data.Model_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetModelInfo", data).then((res) => {
        if (res.status == 200) {
          this.v$.Model.$model = res.data[0].ModelAd;
          this.Model_rowid = res.data[0].Model_rowid;
          this.SelectedUrunTur = res.data[0].pUrunTur;
          this.SelectedMarka = res.data[0].pMarka;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          Model_rowid: this.Model_rowid,
          ModelAd: this.Model,
          UrunTur_rowid: this.SelectedUrunTur,
          Marka_rowid: this.SelectedMarka
        };
        if (this.ModelModelDurum) {
          // Yeni Model
          GlobalServis.GlobalServis("POST", "SetModel", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Model oluşturulmuştur",
                life: 3000,
              });
              this.Refresh();
              this._ModelModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        } else {
          // Edit Model
          GlobalServis.GlobalServis("POST", "UpdateModel", data).then((res) => {
            if (res.status == 200 && res.data == "") {
              this.$toast.add({
                severity: "success",
                summary: "Başarılı",
                detail: "Model düzenlemiştir",
                life: 3000,
              });
              this.Refresh();
              this._ModelModal();
            } else if (res.status == 200 && res.data != "") {
              this.$toast.add({
                severity: "warn",
                summary: "Uyarı",
                detail: res.data,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Hata oldu",
                life: 3000,
              });
            }
          });
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    _ResetForm() {
      this.Model = "";
      this.Model_rowid = "";
      this.SelectedUrunTur = 0;
      this.SelectedModel = 0;
    },
    _ModelModal() {
      this.ModelModelDurum = true;
      this.ModelModal = !this.ModelModal;
      this._ResetForm();
      // if (!this.ModelModal) {
      // }
    },
    GetUrunTur() {
      GlobalServis.GlobalServis("GET", "GetCihazTur", "").then((res) => {
        if (res.status == 200) {
          this.UrunTurTablosu = res.data.CihazTur;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    GetMarka() {
      GlobalServis.GlobalServis("GET", "GetMarka", "").then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.MarkaTablosu = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetUrunModelTablo", "").then((res) => {
        if (res.status == 200) {
          this.UrunModelListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
  },
  mounted() {
    this.InitServis();
    this.GetUrunTur();
    this.GetMarka();
  },
  components: {
    DataTable,
  },
  validations() {
    return {
      Model: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedUrunTur: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedMarka: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
